// Generated by ReScript, PLEASE EDIT WITH CARE

import * as NoBidProvider from "./requests/NoBidProvider.res.js";
import * as SignInRequest from "./requests/SignInRequest.res.js";
import * as SignUpRequest from "./requests/SignUpRequest.res.js";
import * as SignOutRequest from "./requests/SignOutRequest.res.js";
import * as AddToCartRequest from "./requests/AddToCartRequest.res.js";
import * as CreateLeadRequest from "./requests/CreateLeadRequest.res.js";
import * as SendContactMessage from "./requests/SendContactMessage.res.js";
import * as CloneProjectRequest from "./requests/CloneProjectRequest.res.js";
import * as InviteSignUpRequest from "./requests/InviteSignUpRequest.res.js";
import * as ProposalTourRequest from "./requests/ProposalTourRequest.res.js";
import * as CreateProductRequest from "./requests/CreateProductRequest.res.js";
import * as DeleteProjectRequest from "./requests/DeleteProjectRequest.res.js";
import * as FetchProductsRequest from "./requests/FetchProductsRequest.res.js";
import * as FetchProjectsRequest from "./requests/FetchProjectsRequest.res.js";
import * as UpdateProjectRequest from "./requests/UpdateProjectRequest.res.js";
import * as ArchiveProductRequest from "./requests/ArchiveProductRequest.res.js";
import * as FetchCartItemsRequest from "./requests/FetchCartItemsRequest.res.js";
import * as FetchProvidersRequest from "./requests/FetchProvidersRequest.res.js";
import * as FetchResourcesRequest from "./requests/FetchResourcesRequest.res.js";
import * as ForgotPasswordRequest from "./requests/ForgotPasswordRequest.res.js";
import * as PublishProductRequest from "./requests/PublishProductRequest.res.js";
import * as UpdateResourceRequest from "./requests/UpdateResourceRequest.res.js";
import * as ActivateProductRequest from "./requests/ActivateProductRequest.res.js";
import * as ContactProviderRequest from "./requests/ContactProviderRequest.res.js";
import * as CreateModalLeadRequest from "./requests/CreateModalLeadRequest.res.js";
import * as DestroyResourceRequest from "./requests/DestroyResourceRequest.res.js";
import * as FetchUserOrdersRequest from "./requests/FetchUserOrdersRequest.res.js";
import * as ActivateResourceRequest from "./requests/ActivateResourceRequest.res.js";
import * as CreateNewProjectRequest from "./requests/CreateNewProjectRequest.res.js";
import * as DeleteInvitationRequest from "./requests/DeleteInvitationRequest.res.js";
import * as FetchAdminOrdersRequest from "./requests/FetchAdminOrdersRequest.res.js";
import * as FetchResourceCategories from "./requests/FetchResourceCategories.res.js";
import * as ResendInvitationRequest from "./requests/ResendInvitationRequest.res.js";
import * as UnarchiveProductRequest from "./requests/UnarchiveProductRequest.res.js";
import * as UpdateInvitationRequest from "./requests/UpdateInvitationRequest.res.js";
import * as CheckOutModalCartRequest from "./requests/CheckOutModalCartRequest.res.js";
import * as CreateNewResourceRequest from "./requests/CreateNewResourceRequest.res.js";
import * as DeactivateProductRequest from "./requests/DeactivateProductRequest.res.js";
import * as UpdateOrderStatusRequest from "./requests/UpdateOrderStatusRequest.res.js";
import * as UpdateUserProfileRequest from "./requests/UpdateUserProfileRequest.res.js";
import * as CreateProductVideoRequest from "./requests/CreateProductVideoRequest.res.js";
import * as DeactivateResourceRequest from "./requests/DeactivateResourceRequest.res.js";
import * as FetchProvidersListRequest from "./requests/FetchProvidersListRequest.res.js";
import * as UpdateProjectStatusRequest from "./requests/UpdateProjectStatusRequest.res.js";
import * as CreateLocationsVideoRequest from "./requests/CreateLocationsVideoRequest.res.js";
import * as CreateProvidersVideoRequest from "./requests/CreateProvidersVideoRequest.res.js";
import * as ExtendProductEndDateRequest from "./requests/ExtendProductEndDateRequest.res.js";
import * as FetchUserInvitationsRequest from "./requests/FetchUserInvitationsRequest.res.js";
import * as SwitchRoleForProjectRequest from "./requests/SwitchRoleForProjectRequest.res.js";
import * as ZenlayerRegistrationRequest from "./requests/ZenlayerRegistrationRequest.res.js";
import * as CancelProjectCreationRequest from "./requests/CancelProjectCreationRequest.res.js";
import * as DeleteProjectProposalRequest from "./requests/DeleteProjectProposalRequest.res.js";
import * as FetchProjectProposalsRequest from "./requests/FetchProjectProposalsRequest.res.js";
import * as UpdateProductLocationRequest from "./requests/UpdateProductLocationRequest.res.js";
import * as UpdateProjectManagersRequest from "./requests/UpdateProjectManagersRequest.res.js";
import * as UpdateProjectProposalRequest from "./requests/UpdateProjectProposalRequest.res.js";
import * as ArchiveProductsInBatchRequest from "./requests/ArchiveProductsInBatchRequest.res.js";
import * as FetchDashboardProductsRequest from "./requests/FetchDashboardProductsRequest.res.js";
import * as NotifyResourceDownloadRequest from "./requests/NotifyResourceDownloadRequest.res.js";
import * as SelectResourceProviderRequest from "./requests/SelectResourceProviderRequest.res.js";
import * as ActivateProductsInBatchRequest from "./requests/ActivateProductsInBatchRequest.res.js";
import * as FetchResourcesAnalyticsRequest from "./requests/FetchResourcesAnalyticsRequest.res.js";
import * as FetchResourcesDashboardRequest from "./requests/FetchResourcesDashboardRequest.res.js";
import * as UpdateProjectConciergesRequest from "./requests/UpdateProjectConciergesRequest.res.js";
import * as UploadFilesToCloudinaryRequest from "./requests/UploadFilesToCloudinaryRequest.res.js";
import * as ActivateResourcesInBatchRequest from "./requests/ActivateResourcesInBatchRequest.res.js";
import * as ControlPlaneRegistrationRequest from "./requests/ControlPlaneRegistrationRequest.res.js";
import * as CreateNewProjectProposalRequest from "./requests/CreateNewProjectProposalRequest.res.js";
import * as CreateProductReservationRequest from "./requests/CreateProductReservationRequest.res.js";
import * as DeselectResourceProviderRequest from "./requests/DeselectResourceProviderRequest.res.js";
import * as FetchCloudRackZenlayerUsersList from "./requests/FetchCloudRackZenlayerUsersList.res.js";
import * as UnarchiveProductsInBatchRequest from "./requests/UnarchiveProductsInBatchRequest.res.js";
import * as UpdateProductGeneralDataRequest from "./requests/UpdateProductGeneralDataRequest.res.js";
import * as DeactivateProductsInBatchRequest from "./requests/DeactivateProductsInBatchRequest.res.js";
import * as DeactivateResourcesInBatchRequest from "./requests/DeactivateResourcesInBatchRequest.res.js";
import * as SaveProjectGeneralInfoDraftRequest from "./requests/SaveProjectGeneralInfoDraftRequest.res.js";
import * as SearchCitiesStatesCountriesRequest from "./requests/SearchCitiesStatesCountriesRequest.res.js";
import * as UpdateProjectProposalStatusRequest from "./requests/UpdateProjectProposalStatusRequest.res.js";
import * as UpdateProjectProviderStatusRequest from "./requests/UpdateProjectProviderStatusRequest.res.js";
import * as FetchCloudRackControlPlaneUsersList from "./requests/FetchCloudRackControlPlaneUsersList.res.js";
import * as UpdateProductImageDescriptionRequest from "./requests/UpdateProductImageDescriptionRequest.res.js";
import * as UpdateProductVideoDescriptionRequest from "./requests/UpdateProductVideoDescriptionRequest.res.js";
import * as SelectResourceProvidersInBatchRequest from "./requests/SelectResourceProvidersInBatchRequest.res.js";
import * as UpdateUserProfileChangePasswordRequest from "./requests/UpdateUserProfileChangePasswordRequest.res.js";
import * as DeselectResourceProvidersInBatchRequest from "./requests/DeselectResourceProvidersInBatchRequest.res.js";
import * as FetchResourceCategoriesDashboardRequest from "./requests/FetchResourceCategoriesDashboardRequest.res.js";
import * as SaveBareMetalProjectConfigurationRequest from "./requests/SaveBareMetalProjectConfigurationRequest.res.js";
import * as FetchCitiesForColocationRfpProjectRequest from "./requests/FetchCitiesForColocationRfpProjectRequest.res.js";
import * as SaveCloudProjectProvidersSelectionRequest from "./requests/SaveCloudProjectProvidersSelectionRequest.res.js";
import * as SaveInternetRfpProjectConfigurationRequest from "./requests/SaveInternetRfpProjectConfigurationRequest.res.js";
import * as UpdateBareMetalProjectConfigurationRequest from "./requests/UpdateBareMetalProjectConfigurationRequest.res.js";
import * as SaveNetworkProjectProvidersSelectionRequest from "./requests/SaveNetworkProjectProvidersSelectionRequest.res.js";
import * as UpdateCloudProjectProvidersSelectionRequest from "./requests/UpdateCloudProjectProvidersSelectionRequest.res.js";
import * as FetchLocationsForColocationRfpProjectRequest from "./requests/FetchLocationsForColocationRfpProjectRequest.res.js";
import * as SaveCloudDraftProjectAndContinueLaterRequest from "./requests/SaveCloudDraftProjectAndContinueLaterRequest.res.js";
import * as UpdateInternetRfpProjectConfigurationRequest from "./requests/UpdateInternetRfpProjectConfigurationRequest.res.js";
import * as SaveBareMetalProjectConfigurationDraftRequest from "./requests/SaveBareMetalProjectConfigurationDraftRequest.res.js";
import * as SaveNetwokDraftProjectAndContinueLaterRequest from "./requests/SaveNetwokDraftProjectAndContinueLaterRequest.res.js";
import * as UpdateLocationsForColocationRfpProjectRequest from "./requests/UpdateLocationsForColocationRfpProjectRequest.res.js";
import * as UpdateNetworkProjectProvidersSelectionRequest from "./requests/UpdateNetworkProjectProvidersSelectionRequest.res.js";
import * as CreateColocationRfpProjectConfigurationRequest from "./requests/CreateColocationRfpProjectConfigurationRequest.res.js";
import * as SaveCloudDraftProjectProvidersSelectionRequest from "./requests/SaveCloudDraftProjectProvidersSelectionRequest.res.js";
import * as SaveCloudProjectProvidersSelectionDraftRequest from "./requests/SaveCloudProjectProvidersSelectionDraftRequest.res.js";
import * as SaveInternetDraftProjectAndContinueLaterRequest from "./requests/SaveInternetDraftProjectAndContinueLaterRequest.res.js";
import * as SaveInternetRfpProjectConfigurationDraftRequest from "./requests/SaveInternetRfpProjectConfigurationDraftRequest.res.js";
import * as SetStatusOfProposalToPreparingByProviderRequest from "./requests/SetStatusOfProposalToPreparingByProviderRequest.res.js";
import * as SaveBareMetalDraftProjectAndContinueLaterRequest from "./requests/SaveBareMetalDraftProjectAndContinueLaterRequest.res.js";
import * as SaveNetworkDraftProjectProvidersSelectionRequest from "./requests/SaveNetworkDraftProjectProvidersSelectionRequest.res.js";
import * as SaveColocationDraftProjectAndContinueLaterRequest from "./requests/SaveColocationDraftProjectAndContinueLaterRequest.res.js";
import * as SaveColocationRfpProjectConfigurationDraftRequest from "./requests/SaveColocationRfpProjectConfigurationDraftRequest.res.js";
import * as SaveInternetDraftProjectProvidersSelectionRequest from "./requests/SaveInternetDraftProjectProvidersSelectionRequest.res.js";
import * as SaveNetworkProjectMplsNetworkConfigurationRequest from "./requests/SaveNetworkProjectMplsNetworkConfigurationRequest.res.js";
import * as SaveNetworkProjectPrivateLineConfigurationRequest from "./requests/SaveNetworkProjectPrivateLineConfigurationRequest.res.js";
import * as SaveBareMetalProjectProvidersSelectionDraftRequest from "./requests/SaveBareMetalProjectProvidersSelectionDraftRequest.res.js";
import * as SaveCloudProjectIaasCloudStorageConfigurationDraft from "./requests/SaveCloudProjectIaasCloudStorageConfigurationDraft.res.js";
import * as SaveNetworkProjectSdWanNetworkConfigurationRequest from "./requests/SaveNetworkProjectSdWanNetworkConfigurationRequest.res.js";
import * as UpdateBareMetalDraftProjectAndContinueLaterRequest from "./requests/UpdateBareMetalDraftProjectAndContinueLaterRequest.res.js";
import * as UpdateNetworkProjectMplsNetworkConfigurationRequest from "./requests/UpdateNetworkProjectMplsNetworkConfigurationRequest.res.js";
import * as UpdateNetworkProjectPrivateLineConfigurationRequest from "./requests/UpdateNetworkProjectPrivateLineConfigurationRequest.res.js";
import * as SaveCloudProjectIaasCloudServersConfigurationRequest from "./requests/SaveCloudProjectIaasCloudServersConfigurationRequest.res.js";
import * as SaveCloudProjectIaasCloudStorageConfigurationRequest from "./requests/SaveCloudProjectIaasCloudStorageConfigurationRequest.res.js";
import * as UpdateInternetRfpDraftProjectAndContinueLaterRequest from "./requests/UpdateInternetRfpDraftProjectAndContinueLaterRequest.res.js";
import * as UpdateNetworkMplsDraftProjectAndContinueLaterRequest from "./requests/UpdateNetworkMplsDraftProjectAndContinueLaterRequest.res.js";
import * as UpdateNetworkProjectSdWanNetworkConfigurationRequest from "./requests/UpdateNetworkProjectSdWanNetworkConfigurationRequest.res.js";
import * as SaveInternetDraftWithDocumentsAndContinueLaterRequest from "./requests/SaveInternetDraftWithDocumentsAndContinueLaterRequest.res.js";
import * as UpdateNetworkSdWanDraftProjectAndContinueLaterRequest from "./requests/UpdateNetworkSdWanDraftProjectAndContinueLaterRequest.res.js";
import * as SaveNetworkProjectMplsNetworkConfigurationDraftRequest from "./requests/SaveNetworkProjectMplsNetworkConfigurationDraftRequest.res.js";
import * as SaveNetworkProjectPrivateLineConfigurationDraftRequest from "./requests/SaveNetworkProjectPrivateLineConfigurationDraftRequest.res.js";
import * as UpdateCloudProjectIaasCloudServersConfigurationRequest from "./requests/UpdateCloudProjectIaasCloudServersConfigurationRequest.res.js";
import * as UpdateCloudProjectIaasCloudStorageConfigurationRequest from "./requests/UpdateCloudProjectIaasCloudStorageConfigurationRequest.res.js";
import * as SaveNetworkProjectSdWanNetworkConfigurationDraftRequest from "./requests/SaveNetworkProjectSdWanNetworkConfigurationDraftRequest.res.js";
import * as SaveCloudProjectIaasCloudServersConfigurationDraftRequest from "./requests/SaveCloudProjectIaasCloudServersConfigurationDraftRequest.res.js";
import * as UpdateCloudIaasServersDraftProjectAndContinueLaterRequest from "./requests/UpdateCloudIaasServersDraftProjectAndContinueLaterRequest.res.js";
import * as UpdateCloudIaasStorageDraftProjectAndContinueLaterRequest from "./requests/UpdateCloudIaasStorageDraftProjectAndContinueLaterRequest.res.js";
import * as SaveNetworkProjectDraftWithDocumentsAndContinueLaterRequest from "./requests/SaveNetworkProjectDraftWithDocumentsAndContinueLaterRequest.res.js";
import * as UpdateNetworkPrivateLineDraftProjectAndContinueLaterRequest from "./requests/UpdateNetworkPrivateLineDraftProjectAndContinueLaterRequest.res.js";

var fetchDashboardProducts = FetchDashboardProductsRequest.exec;

var fetchResourcesDashboard = FetchResourcesDashboardRequest.exec;

var fetchResourcesAnalytics = FetchResourcesAnalyticsRequest.exec;

var fetchResourceCategoriesDashboard = FetchResourceCategoriesDashboardRequest.exec;

var activateResource = ActivateResourceRequest.exec;

var activateResourcesInBatch = ActivateResourcesInBatchRequest.exec;

var deactivateResource = DeactivateResourceRequest.exec;

var deactivateResourcesInBatch = DeactivateResourcesInBatchRequest.exec;

var createNewResource = CreateNewResourceRequest.exec;

var destroyResource = DestroyResourceRequest.exec;

var updateResource = UpdateResourceRequest.exec;

var createProduct = CreateProductRequest.exec;

var publishProduct = PublishProductRequest.exec;

var activateProduct = ActivateProductRequest.exec;

var deactivateProduct = DeactivateProductRequest.exec;

var extendProductEndDate = ExtendProductEndDateRequest.exec;

var archiveProduct = ArchiveProductRequest.exec;

var unarchiveProduct = UnarchiveProductRequest.exec;

var activateProductsInBatch = ActivateProductsInBatchRequest.exec;

var deactivateProductsInBatch = DeactivateProductsInBatchRequest.exec;

var archiveProductsInBatch = ArchiveProductsInBatchRequest.exec;

var unarchiveProductsInBatch = UnarchiveProductsInBatchRequest.exec;

var updateProductGeneralData = UpdateProductGeneralDataRequest.exec;

var updateProductLocation = UpdateProductLocationRequest.exec;

var createProductVideo = CreateProductVideoRequest.exec;

var updateProductImageDescription = UpdateProductImageDescriptionRequest.exec;

var updateProductVideoDescription = UpdateProductVideoDescriptionRequest.exec;

var uploadFilesToCloudinary = UploadFilesToCloudinaryRequest.exec;

var fetchProducts = FetchProductsRequest.exec;

var fetchProjects = FetchProjectsRequest.exec;

var fetchLocationsForColocationRfpProject = FetchLocationsForColocationRfpProjectRequest.exec;

var fetchCitiesForColocationRfpProject = FetchCitiesForColocationRfpProjectRequest.exec;

var updateLocationsForColocationRfpProject = UpdateLocationsForColocationRfpProjectRequest.exec;

var updateProjectStatus = UpdateProjectStatusRequest.exec;

var cloneProject = CloneProjectRequest.exec;

var fetchProjectProposals = FetchProjectProposalsRequest.exec;

var updateProjectProposalStatus = UpdateProjectProposalStatusRequest.exec;

var fetchUserOrders = FetchUserOrdersRequest.exec;

var createProductReservation = CreateProductReservationRequest.exec;

var createLead = CreateLeadRequest.exec;

var createModalLead = CreateModalLeadRequest.exec;

var searchCitiesStatesCountries = SearchCitiesStatesCountriesRequest.exec;

var signIn = SignInRequest.exec;

var signUp = SignUpRequest.exec;

var forgotPassword = ForgotPasswordRequest.exec;

var signOut = SignOutRequest.exec;

var fetchProvidersList = FetchProvidersListRequest.exec;

var fetchProviders = FetchProvidersRequest.exec;

var noBidProvider = NoBidProvider.exec;

var setStatusOfProposalToPreparingByProvider = SetStatusOfProposalToPreparingByProviderRequest.exec;

var contactProvider = ContactProviderRequest.exec;

var fetchResourceCategories = FetchResourceCategories.exec;

var sendContactMessage = SendContactMessage.exec;

var notifyResourceDownload = NotifyResourceDownloadRequest.exec;

var updateUserProfile = UpdateUserProfileRequest.exec;

var updateUserProfileChangePassword = UpdateUserProfileChangePasswordRequest.exec;

var fetchAdminOrders = FetchAdminOrdersRequest.exec;

var updateOrderStatus = UpdateOrderStatusRequest.exec;

var addToCart = AddToCartRequest.exec;

var fetchCartItems = FetchCartItemsRequest.exec;

var checkOutModalCart = CheckOutModalCartRequest.exec;

var fetchResources = FetchResourcesRequest.exec;

var inviteSignUp = InviteSignUpRequest.exec;

var fetchUserInvitations = FetchUserInvitationsRequest.exec;

var updateInvitation = UpdateInvitationRequest.exec;

var deleteInvitation = DeleteInvitationRequest.exec;

var resendInvitation = ResendInvitationRequest.exec;

var createColocationRfpProjectConfiguration = CreateColocationRfpProjectConfigurationRequest.exec;

var createNewProjectProposal = CreateNewProjectProposalRequest.exec;

var cancelProjectCreation = CancelProjectCreationRequest.exec;

var updateProjectProposal = UpdateProjectProposalRequest.exec;

var createNewProject = CreateNewProjectRequest.exec;

var updateProject = UpdateProjectRequest.exec;

var deleteProjectProposal = DeleteProjectProposalRequest.exec;

var saveProjectGeneralInfoDraft = SaveProjectGeneralInfoDraftRequest.exec;

var saveColocationRfpProjectConfigurationDraft = SaveColocationRfpProjectConfigurationDraftRequest.exec;

var saveCloudProjectIaasCloudServersConfiguration = SaveCloudProjectIaasCloudServersConfigurationRequest.exec;

var saveCloudProjectIaasCloudServersConfigurationDraft = SaveCloudProjectIaasCloudServersConfigurationDraftRequest.exec;

var updateCloudProjectIaasCloudServersConfiguration = UpdateCloudProjectIaasCloudServersConfigurationRequest.exec;

var saveProjectProvidersSelection = SaveCloudProjectProvidersSelectionRequest.exec;

var saveProjectProvidersSelectionDraft = SaveCloudProjectProvidersSelectionDraftRequest.exec;

var updateProjectProvidersSelection = UpdateCloudProjectProvidersSelectionRequest.exec;

var switchProjectRole = SwitchRoleForProjectRequest.exec;

var saveProjectConcierges = UpdateProjectConciergesRequest.exec;

var saveProjectManagers = UpdateProjectManagersRequest.exec;

var saveCloudProjectIaasCloudStorageConfigurationDraft = SaveCloudProjectIaasCloudStorageConfigurationDraft.exec;

var saveCloudProjectIaasCloudStorageConfiguration = SaveCloudProjectIaasCloudStorageConfigurationRequest.exec;

var updateCloudProjectIaasCloudStorageConfiguration = UpdateCloudProjectIaasCloudStorageConfigurationRequest.exec;

var saveColocationDraftProjectAndContinueLater = SaveColocationDraftProjectAndContinueLaterRequest.exec;

var saveCloudDraftProjectAndContinueLater = SaveCloudDraftProjectAndContinueLaterRequest.exec;

var saveCloudDraftProjectProvidersSelection = SaveCloudDraftProjectProvidersSelectionRequest.exec;

var updateCloudIaasServersDraftProjectAndContinueLater = UpdateCloudIaasServersDraftProjectAndContinueLaterRequest.exec;

var updateCloudIaasStorageDraftProjectAndContinueLater = UpdateCloudIaasStorageDraftProjectAndContinueLaterRequest.exec;

var deleteProject = DeleteProjectRequest.exec;

var saveInternetRfpProjectConfigurationDraft = SaveInternetRfpProjectConfigurationDraftRequest.exec;

var saveInternetRfpProjectConfiguration = SaveInternetRfpProjectConfigurationRequest.exec;

var updateInternetRfpProjectConfiguration = UpdateInternetRfpProjectConfigurationRequest.exec;

var saveInternetDraftProjectAndContinueLater = SaveInternetDraftProjectAndContinueLaterRequest.exec;

var updateInternetRfpDraftProjectAndContinueLater = UpdateInternetRfpDraftProjectAndContinueLaterRequest.exec;

var saveInternetDraftProjectProvidersSelection = SaveInternetDraftProjectProvidersSelectionRequest.exec;

var saveInternetDraftWithDocumentsAndContinueLater = SaveInternetDraftWithDocumentsAndContinueLaterRequest.exec;

var saveBareMetalProjectConfigurationDraft = SaveBareMetalProjectConfigurationDraftRequest.exec;

var saveBareMetalProjectConfiguration = SaveBareMetalProjectConfigurationRequest.exec;

var updateBareMetalProjectConfiguration = UpdateBareMetalProjectConfigurationRequest.exec;

var saveBareMetalDraftProjectAndContinueLater = SaveBareMetalDraftProjectAndContinueLaterRequest.exec;

var updateBareMetalDraftProjectAndContinueLater = UpdateBareMetalDraftProjectAndContinueLaterRequest.exec;

var saveBareMetalDraftProjectProvidersSelection = SaveBareMetalProjectProvidersSelectionDraftRequest.exec;

var saveNetworkProjectSdWanNetworkConfigurationDraft = SaveNetworkProjectSdWanNetworkConfigurationDraftRequest.exec;

var saveNetworkProjectSdWanNetworkConfiguration = SaveNetworkProjectSdWanNetworkConfigurationRequest.exec;

var updateNetworkProjectSdWanNetworkConfiguration = UpdateNetworkProjectSdWanNetworkConfigurationRequest.exec;

var saveNetworkProjectDraftWithDocumentsAndContinueLater = SaveNetworkProjectDraftWithDocumentsAndContinueLaterRequest.exec;

var updateNetworkSdWanDraftProjectAndContinueLater = UpdateNetworkSdWanDraftProjectAndContinueLaterRequest.exec;

var saveNetworkProjectPrivateLineConfigurationDraft = SaveNetworkProjectPrivateLineConfigurationDraftRequest.exec;

var saveNetworkProjectPrivateLineConfiguration = SaveNetworkProjectPrivateLineConfigurationRequest.exec;

var updateNetworkProjectPrivateLineConfiguration = UpdateNetworkProjectPrivateLineConfigurationRequest.exec;

var updateNetworkPrivateLineDraftProjectAndContinueLater = UpdateNetworkPrivateLineDraftProjectAndContinueLaterRequest.exec;

var saveNetworkProjectProvidersSelection = SaveNetworkProjectProvidersSelectionRequest.exec;

var updateNetworkProjectProvidersSelection = UpdateNetworkProjectProvidersSelectionRequest.exec;

var saveNetworkDraftProjectProvidersSelection = SaveNetworkDraftProjectProvidersSelectionRequest.exec;

var saveNetwokDraftProjectAndContinueLater = SaveNetwokDraftProjectAndContinueLaterRequest.exec;

var saveNetworkProjectMplsNetworkConfigurationDraft = SaveNetworkProjectMplsNetworkConfigurationDraftRequest.exec;

var saveNetworkProjectMplsNetworkConfiguration = SaveNetworkProjectMplsNetworkConfigurationRequest.exec;

var updateNetworkProjectMplsNetworkConfiguration = UpdateNetworkProjectMplsNetworkConfigurationRequest.exec;

var updateNetworkMplsDraftProjectAndContinueLater = UpdateNetworkMplsDraftProjectAndContinueLaterRequest.exec;

var proposalTourRequest = ProposalTourRequest.exec;

var controlPlaneRegistrationRequest = ControlPlaneRegistrationRequest.exec;

var zenlayerRegistrationRequest = ZenlayerRegistrationRequest.exec;

var fetchCloudRackControlPlaneUsersList = FetchCloudRackControlPlaneUsersList.exec;

var fetchCloudRackZenlayerUsersList = FetchCloudRackZenlayerUsersList.exec;

var updateProjectProviderStatus = UpdateProjectProviderStatusRequest.exec;

var selectResourceProvider = SelectResourceProviderRequest.exec;

var selectResourceProvidersInBatch = SelectResourceProvidersInBatchRequest.exec;

var deselectResourceProvider = DeselectResourceProviderRequest.exec;

var deselectResourceProvidersInBatch = DeselectResourceProvidersInBatchRequest.exec;

var createProviderVideo = CreateProvidersVideoRequest.exec;

var createLocationVideo = CreateLocationsVideoRequest.exec;

export {
  fetchDashboardProducts ,
  fetchResourcesDashboard ,
  fetchResourcesAnalytics ,
  fetchResourceCategoriesDashboard ,
  activateResource ,
  activateResourcesInBatch ,
  deactivateResource ,
  deactivateResourcesInBatch ,
  createNewResource ,
  destroyResource ,
  updateResource ,
  createProduct ,
  publishProduct ,
  activateProduct ,
  deactivateProduct ,
  extendProductEndDate ,
  archiveProduct ,
  unarchiveProduct ,
  activateProductsInBatch ,
  deactivateProductsInBatch ,
  archiveProductsInBatch ,
  unarchiveProductsInBatch ,
  updateProductGeneralData ,
  updateProductLocation ,
  createProductVideo ,
  updateProductImageDescription ,
  updateProductVideoDescription ,
  uploadFilesToCloudinary ,
  fetchProducts ,
  fetchProjects ,
  fetchLocationsForColocationRfpProject ,
  fetchCitiesForColocationRfpProject ,
  updateLocationsForColocationRfpProject ,
  updateProjectStatus ,
  cloneProject ,
  fetchProjectProposals ,
  updateProjectProposalStatus ,
  fetchUserOrders ,
  createProductReservation ,
  createLead ,
  createModalLead ,
  searchCitiesStatesCountries ,
  signIn ,
  signUp ,
  forgotPassword ,
  signOut ,
  fetchProvidersList ,
  fetchProviders ,
  noBidProvider ,
  setStatusOfProposalToPreparingByProvider ,
  contactProvider ,
  fetchResourceCategories ,
  sendContactMessage ,
  notifyResourceDownload ,
  updateUserProfile ,
  updateUserProfileChangePassword ,
  fetchAdminOrders ,
  updateOrderStatus ,
  addToCart ,
  fetchCartItems ,
  checkOutModalCart ,
  fetchResources ,
  inviteSignUp ,
  fetchUserInvitations ,
  updateInvitation ,
  deleteInvitation ,
  resendInvitation ,
  createColocationRfpProjectConfiguration ,
  createNewProjectProposal ,
  cancelProjectCreation ,
  updateProjectProposal ,
  createNewProject ,
  updateProject ,
  deleteProjectProposal ,
  saveProjectGeneralInfoDraft ,
  saveColocationRfpProjectConfigurationDraft ,
  saveCloudProjectIaasCloudServersConfiguration ,
  saveCloudProjectIaasCloudServersConfigurationDraft ,
  updateCloudProjectIaasCloudServersConfiguration ,
  saveProjectProvidersSelection ,
  saveProjectProvidersSelectionDraft ,
  updateProjectProvidersSelection ,
  switchProjectRole ,
  saveProjectConcierges ,
  saveProjectManagers ,
  saveCloudProjectIaasCloudStorageConfigurationDraft ,
  saveCloudProjectIaasCloudStorageConfiguration ,
  updateCloudProjectIaasCloudStorageConfiguration ,
  saveColocationDraftProjectAndContinueLater ,
  saveCloudDraftProjectAndContinueLater ,
  saveCloudDraftProjectProvidersSelection ,
  updateCloudIaasServersDraftProjectAndContinueLater ,
  updateCloudIaasStorageDraftProjectAndContinueLater ,
  deleteProject ,
  saveInternetRfpProjectConfigurationDraft ,
  saveInternetRfpProjectConfiguration ,
  updateInternetRfpProjectConfiguration ,
  saveInternetDraftProjectAndContinueLater ,
  updateInternetRfpDraftProjectAndContinueLater ,
  saveInternetDraftProjectProvidersSelection ,
  saveInternetDraftWithDocumentsAndContinueLater ,
  saveBareMetalProjectConfigurationDraft ,
  saveBareMetalProjectConfiguration ,
  updateBareMetalProjectConfiguration ,
  saveBareMetalDraftProjectAndContinueLater ,
  updateBareMetalDraftProjectAndContinueLater ,
  saveBareMetalDraftProjectProvidersSelection ,
  saveNetworkProjectSdWanNetworkConfigurationDraft ,
  saveNetworkProjectSdWanNetworkConfiguration ,
  updateNetworkProjectSdWanNetworkConfiguration ,
  saveNetworkProjectDraftWithDocumentsAndContinueLater ,
  updateNetworkSdWanDraftProjectAndContinueLater ,
  saveNetworkProjectPrivateLineConfigurationDraft ,
  saveNetworkProjectPrivateLineConfiguration ,
  updateNetworkProjectPrivateLineConfiguration ,
  updateNetworkPrivateLineDraftProjectAndContinueLater ,
  saveNetworkProjectProvidersSelection ,
  updateNetworkProjectProvidersSelection ,
  saveNetworkDraftProjectProvidersSelection ,
  saveNetwokDraftProjectAndContinueLater ,
  saveNetworkProjectMplsNetworkConfigurationDraft ,
  saveNetworkProjectMplsNetworkConfiguration ,
  updateNetworkProjectMplsNetworkConfiguration ,
  updateNetworkMplsDraftProjectAndContinueLater ,
  proposalTourRequest ,
  controlPlaneRegistrationRequest ,
  zenlayerRegistrationRequest ,
  fetchCloudRackControlPlaneUsersList ,
  fetchCloudRackZenlayerUsersList ,
  updateProjectProviderStatus ,
  selectResourceProvider ,
  selectResourceProvidersInBatch ,
  deselectResourceProvider ,
  deselectResourceProvidersInBatch ,
  createProviderVideo ,
  createLocationVideo ,
}
/* NoBidProvider Not a pure module */
